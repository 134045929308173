import React, { useState, useEffect } from 'react';

const CookiePolicy = () => {
    const [isOpen, setIsOpen] = useState(true); 

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (cookiesAccepted === 'true') {
            setIsOpen(false); 
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setIsOpen(false); 
        console.log("Accepted");
    };

    const handleDecline = () => {
        localStorage.setItem('cookiesAccepted', 'false');
        setIsOpen(false); 
    };

    if (!isOpen) {
        return null; 
    }

    return (
        <div className="cookie-policy">
            <div className='cookieBody'>
                <h4 className='Cookie-head'>Cookie Policy</h4>
                <p>We use cookies to ensure you get the best experience on our website.</p>
                
             </div>
             <div className="cookie-buttons">
                    <button className="p-2 bg-transparent border-info" onClick={handleAccept}>Accept</button>
                    <button className="p-2 bg-transparent border-info" onClick={handleDecline}>Decline</button>
                </div>
        </div>
    );
};

export default CookiePolicy;
