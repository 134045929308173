import React from 'react';
import 'bootstrap';
import 'aos'
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/portfolio/Labiconicslogo.png'
import rabindranadth from '../assets/img/teams/ravee-removebg-preview.png'
import licaPDF from '../assets/pdf/lica.pdf';
import { FaDownload } from 'react-icons/fa';


const Careers = () => {

    return (<div>

        <section id="CAREERS" className='mb-5'>
            <div className='container-fluid text-light career-container text-start'>
                <div className='row pt-3 pb-2'>
                    <div className="text-center mb-5 mt-5" data-aos="zoom-out">
                        <h1><b>CAREERS</b></h1>
                    </div>
                    <div className='col-12 col-lg-6 d-flex flex-column justify-content-center p-5'>
                        <h1 className='text-styling custom-italic fs-2'>
                            <strong>Embark on a Journey with Labiconics: </strong>
                            <p style={{ fontSize: '18px' }} ><b>Join us at Lab Iconics Technologies LLP, where we're pioneering innovative solutions that transform laboratory operations.</b></p>
                        </h1>
                        <p className='font-italic' style={{ fontSize: '18px' }}>We're a team of passionate individuals dedicated to driving excellence in Lab Informatics technology and making a meaningful impact in the pharmaceutical and biotechnology industries.</p>
                        {/* <form >
                            <div className='d-flex flex-row justify-content-center text-center' > <button className='button'><a href='#faq'><b>Apply now</b></a></button> </div>
                        </form>
                        <p>Send your resume to hr@labiconics.com. We look forward to welcoming you to our team!</p> */}
                    </div>
                    <div className='col-12 col-lg-6 d-flex row justify-content-end ml-md-5 mt-5' >
                        <img src={require('../assets/img/Lims/ourvalues1.png')} />
                    </div>

                </div>
                <svg className="hero-waves" style={{ background: 'rgba(4,52,111)' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none">
                    <defs>
                        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="wave1">
                        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                    <g className="wave2">
                        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                    </g>
                    <g className="wave3">
                        <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
                    </g>
                </svg>
            </div>

            <main id="main">
                <section className="about text-start">
                    <div className="container">
                        <div className="text-center mb-sm-5 mb-lg-0" data-aos="zoom-out">
                            <h2 style={{ color: 'rgba(4,52,111)' }}><b>WHY JOIN LAB ICONICS</b></h2>
                        </div>

                        <div className="row" data-aos="fade-up">
                            <div className="col-lg-12 order-sm-2 order-lg-1 mt-5">
                                <p className="fs-5" style={{fontSize:'16px'}}>
                                    At Lab Iconics, we believe in creating a workplace where every individual can thrive, grow, and make a difference. Join us in shaping the future of Lab Informatics and embark on a rewarding career journey with endless possibilities.
                                </p>
                                <div className="services text-start">
                                    <div class="container services mt-5" >
                                        <div class="row text-start">
                                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">

                                                    <h4 class="mt-2 fs-5" style={{ color: 'rgba(4,52,111)' }}><b>Cutting-Edge Innovation</b></h4>
                                                    <p class="text-start " style={{ fontSize: '16px', marginLeft: '0px', wordBreak: '-1' }}>Join a dynamic team at the forefront of Lab Informatics technology, driving innovation and shaping the future of the industry.</p>                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">

                                                    <h4 class="fs-5 mt-2" style={{color:'rgba(4,52,111)'}}><b>Impactful Work</b></h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Contribute to projects that make a meaningful difference in the pharmaceutical and biotechnology industries, streamlining processes and improving efficiency.</p>

                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">


                                                    <h4 class="fs-5 mt-2" style={{color:'rgba(4,52,111)'}}><b>Professional Growth</b></h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Access continuous learning and development opportunities to enhance your skills and expand your knowledge in the rapidly evolving field of Lab Informatics.</p>

                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                                    <h4 class="fs-5 mt-2" style={{color:'rgba(4,52,111)'}}><b>Collaborative Culture </b></h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Join a collaborative and inclusive work environment where your ideas are valued, and teamwork is celebrated.</p>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-6 mt-2 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">


                                                    <h4 class="fs-5" style={{color:'rgba(4,52,111)'}}><b>Career Advancement </b></h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Benefit from opportunities for career advancement and progression, with clear pathways for growth and development within the organization.</p>

                                                   
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-6 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">


                                                    <h4 class="fs-5 mt-2" style={{color:'rgba(4,52,111)'}}><b>Competitive Compensation</b></h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Enjoy a competitive salary and benefits package, including health insurance, retirement plans, and other incentives.</p>

                                                   
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-6 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">


                                                    <h4 class="fs-5 mt-2" style={{color:'rgba(4,52,111)'}}><b>Work-Life Balance</b></h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Maintain a healthy work-life balance with flexible work arrangements and a supportive workplace culture that prioritizes employee well-being.</p>
                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-6 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                                    <h4 class="fs-5 mt-2" style={{color:'rgba(4,52,111)'}}><b>Innovation Hub</b></h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Be part of an innovation hub where creativity thrives, and where you'll have the opportunity to work on groundbreaking projects that push the boundaries of Lab Informatics technology.</p>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </main>

            <main >
                <section className="about text-start">
                    <div className="container">
                        <div className="text-center" data-aos="zoom-out">
                            <h2 style={{ color: 'rgba(4,52,111)' }}><b>OUR VALUES</b></h2>
                        </div>

                        <div className="row" data-aos="fade-up">
                            <div className="order-sm-2 order-lg-1">
                                <div className="services text-start">
                                    <div class="container services mt-5" >
                                        <div class="row text-start">
                                            <div class="col-sm-12 col-lg-6 col-md-6 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">

                                                    <h1 class="title mt-5 fs-3" style={{color:'rgba(4,52,111)'}}>Teamwork </h1>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>At Lab Iconics, we believe in the power of teamwork. Our collaborative environment fosters creativity, innovation, and mutual support among team members. By working together towards common goals, we leverage the diverse skills and perspectives of our talented individuals to deliver exceptional solutions to our customers.</p>

                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-lg-6 col-md-6 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">

                                                    <h4 class="title mt-5 fs-3" style={{color:'rgba(4,52,111)'}}>Quality </h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>2.	Quality: Quality is at the heart of everything we do at Lab Iconics. We are committed to delivering products and services of the highest standard, meeting or exceeding the expectations of our customers. Through meticulous attention to detail, adherence to industry best practices, and a culture of continuous improvement, we ensure that our solutions are reliable, efficient, and of superior quality.</p>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-lg-6 col-md-6 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">


                                                    <h4 class="title mt-5 fs-3" style={{color:'rgba(4,52,111)'}}>Trust</h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Trust is the foundation of our relationships, both within our team and with our customers. At Lab Iconics, we prioritize transparency, reliability, and accountability in all our interactions. By consistently delivering on our promises, acting with integrity, and respecting the confidentiality and trust placed in us, we build strong and lasting relationships built on trust.</p>

                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 mb-5">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">


                                                    <h4 class="title mt-5 fs-3" style={{color:'rgba(4,52,111)'}}>Integrity </h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>Integrity is a core value that guides our conduct and decision-making at Lab Iconics. We operate with honesty, fairness, and ethical principles in all aspects of our business. Our commitment to integrity means that we always do the right thing, even when no one is watching. By upholding the highest standards of professionalism and ethical behavior, we earn the trust and respect of our customers and partners.</p>

                                                </div>
                                            </div>
                                            <div class="col-lg-3"></div>

                                            <div class="col-lg-6 col-md-6">
                                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">

                                                    <h4 class="title mt-5 fs-3" style={{color:'rgba(4,52,111)'}}>Customer Service</h4>
                                                    <p class="text-start" style={{ fontSize: '16px' }}>At Lab Iconics, customer satisfaction is our top priority. We are dedicated to providing exceptional customer service, understanding our customers' needs, and exceeding their expectations. Our responsive and proactive approach ensures that we deliver personalized solutions tailored to the unique requirements of each customer. We are committed to building long-term relationships based on trust, reliability, and a deep understanding of our customers' goals and challenges.</p>

                                                </div>
                                            </div>
                                            <div class="col-lg-3"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </main>

            <form action='mailto:hr@labiconics.com'>
                <section id="faq" class="faq">
                    <div class="container">
                        <div class="section-title text-center" data-aos="zoom-out">
                            <p style={{ color: 'rgba(4,52,111)', textAlign: 'center' }} className='text-center'>CURRENT OPENINGS</p>
                        </div>

                        <ul class="faq-list">
                            <li>
                                <div className='container'>
                                    <div className='row'>
                                        <div data-bs-toggle="collapse" class="collapsed question text-start ml-5 col-6" href="#faq1"><b>Senior Executive Operations - Lab Informatics </b><i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
                                        <div className='col-6 question collapsed '><button type="submit" className='p-1 rounded-3 border-none text-light' style={{backgroundColor:'#37517e'}}>Apply now</button></div>
                                    </div>
                                </div>
                                <div id="faq1" class="collapse" data-bs-parent=".faq-list">
                                    <p className='text-start' style={{ color: 'rgba(4,52,111)' }}><b>Role and Responsibilities</b></p>
                                    <p>
                                        <ul className='text-start'>
                                            <p>• Handle employee relations, address grievances, and ensure compliance with HR policies and regulations.</p>
                                            <p>• Maintain accurate records and documentation, including employee files, contracts, and agreements.</p>
                                            <p>• Handle accounts payable and receivable, invoicing, and financial reporting.</p>
                                            <p>• Develop and implement sales strategies to meet revenue targets and expand the client base.</p>
                                        </ul>
                                    </p>
                                    <p className='text-start' style={{ color: 'rgba(4,52,111)' }}><b>Skills Required</b></p>
                                    <p>
                                        <ul className='text-start'>
                                            <p>• Ability to lead and manage teams effectively across multiple functions.</p>
                                            <p>• Ability to analyze financial data, prepare budgets, and make informed decisions.</p>
                                            <p>• Excellent organizational skills to manage multiple responsibilities and prioritize tasks.</p>
                                            <p>• Good communication skills to drive sales and negotiations.</p>
                                        </ul>
                                    </p>
                                </div>
                            </li>


                            <li>

                                <div className='container'>
                                    <div className='row'>
                                        <div data-bs-toggle="collapse" href="#faq2" class="collapsed question text-start col-6"><b>Software Architect - Lab Informatics</b> <i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
                                        <div className='col-6 question collapsed '><button type="submit" className='p-1 rounded-3 border-none text-light' style={{backgroundColor:'#37517e'}}>Apply now</button></div>
                                    </div>
                                </div>
                                <div id="faq2" class="collapse" data-bs-parent=".faq-list">
                                    <p className='text-start' style={{ color: 'rgba(4,52,111)' }}><b>Role and Responsibilities</b></p>
                                    <p>
                                        <ul className='text-start'>
                                            <p>• Design the overall architecture of software systems, considering factors like scalability, performance, security, and maintainability.</p>
                                            <p>• Create architectural blueprints, diagrams, and technical specifications.</p>
                                            <p>• Evaluate and select appropriate technologies, frameworks, and tools based on project requirements.</p>
                                            <p>• Stay updated with emerging technologies and industry trends.</p>
                                            <p>• Define coding standards, guidelines, and best practices to ensure high-quality code.</p>
                                            <p>• Conduct code reviews and provide feedback to developers.</p>
                                            <p>• Design interfaces and APIs for seamless integration between different components of the system.</p>
                                            <p>• Identify performance bottlenecks and optimize system performance through architectural changes and code optimizations.</p>
                                            <p>• Conduct performance testing and analysis.</p>
                                            <p>• Implement redundancy and failover mechanisms to ensure high availability and reliability</p>
                                            <p>• Define and implement security measures to protect the system against vulnerabilities and cyber threats.</p>
                                            <p>• Ensure compliance with security standards and regulations.</p>
                                            <p>• Create and maintain technical documentation, including architecture documents, design patterns, and APIs.</p>
                                        </ul>
                                    </p>
                                    <p className='text-start' style={{ color: 'rgba(4,52,111)' }}><b>Skills Required</b></p>
                                    <p>
                                        <ul className='text-start'>
                                            <p>• Experience with web development frameworks (e.g., Spring, .NET) and databases (e.g., SQL, NoSQL) and any programming language</p>
                                            <p>• Experience in designing and implementing scalable, distributed systems</p>
                                            <p>• Strong analytical and problem-solving skills to troubleshoot complex technical issues.</p>
                                            <p>• Leadership skills to lead technical discussions, guide development teams, and drive projects to completion.</p>
                                        </ul>
                                    </p>
                                </div>
                            </li>

                            <li>

                                <div className='container'>
                                    <div className='row'>
                                        <div data-bs-toggle="collapse" href="#faq3" class="collapsed question text-start col-6"><b>Senior Software Developer - Lab Informatics</b><i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
                                        <div className='col-6 question collapsed '><button type="submit" className='p-1 rounded-3 border-none text-light' style={{backgroundColor:'#37517e'}}>Apply now</button></div>
                                    </div>
                                </div>
                                <div id="faq3" class="collapse" data-bs-parent=".faq-list">
                                    <p className='text-start' style={{ color: 'rgba(4,52,111)' }}><b>Role and Responsibilities</b></p>
                                    <p>
                                        <ul className='text-start'>
                                            <p>•	Design, develop, and maintain high-quality software solutions.</p>
                                            <p>•	Conduct code reviews to ensure code quality, adherence to coding standards, and best practices.</p>
                                            <p>•	Work closely with architects and product managers to translate requirements into technical solutions.</p>
                                            <p>•	Mentor junior developers and contribute to a culture of excellence in software development.</p>
                                        </ul>
                                    </p>
                                    <p className='text-start' type="submit" style={{ color: 'rgba(4,52,111)' }}><b>Skills Required</b></p>
                                    <p>
                                        <ul className='text-start'>
                                            <p>•	Proficiency in software development technologies like REACT, PostgreSQL and RUST frameworks.</p>
                                            <p>•	Experience with version control systems like Git and familiarity with development tools and IDEs such as IntelliJ, Visual Studio, or Eclipse.</p>
                                            <p>•	Experience with Lab Informatics projects is a plus.</p>
                                            <p>•	Team player with strong problem-solving and analytical skills.</p>
                                        </ul>
                                    </p>
                                </div>
                            </li>

                            <li>
                                <div className='container'>
                                    <div className='row'>
                                        <div data-bs-toggle="collapse" href="#faq4" class="collapsed question text-start col-6"><b>Business Development Manager - Lab Informatics</b><i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
                                        <div className='col-6 question collapsed '><button type="submit" className='p-1 rounded-3 border-none text-light' style={{backgroundColor:'#37517e'}}>Apply now</button></div>
                                    </div>
                                </div>
                                <div id="faq4" class="collapse" data-bs-parent=".faq-list">
                                    <p className='text-start' style={{ color: 'rgba(4,52,111)' }}><b>Role and Responsibilities</b></p>
                                    <p>
                                        <ul className='text-start'>
                                            <p>•	Drive business growth by identifying and pursuing new opportunities in the Lab Informatics market.</p>
                                            <p>•	Build and maintain relationships with clients and partners.</p>
                                            <p>•	Develop and execute strategic business plans to expand market presence.</p>
                                            <p>•	Collaborate with internal teams to ensure successful delivery of solutions to clients.</p>
                                        </ul>
                                    </p>
                                    <p className='text-start' style={{ color: 'rgba(4,52,111)' }}><b>Skills Required</b></p>
                                    <p>
                                        <ul className='text-start'>
                                            <p>•	Proven track record in business development, preferably in the Lab Informatics domain.</p>
                                            <p>•	Strong negotiation and communication skills.</p>
                                            <p>•	Strategic thinking and ability to identify market trends and opportunities.</p>
                                            <p>•	Results-driven with a focus on achieving targets.</p>
                                        </ul>
                                    </p>
                                </div>
                            </li>
                        </ul>

                    </div>
                </section>
            </form>

        </section>

    </div>
    );
};

export default Careers;


