import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import About from './components/About';
import Home from './components/Home';
import Lims from './components/Lims';
import Eln from './components/Eln';
import Adbs from './components/Adbs';
import Careers from './components/Careers';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Dms from './components/Dms';
import Qms from './components/Qms';
import Demo from './components/Demo';
import Downloads from './components/Downloads';
import SmallScreensNavbar from './components/smallscreennav';
import CookiePolicy  from './components/CookiePolicy';

function App() {
  const [currentPage, setCurrentPage] = useState('home'); // Default to 'home'

  const handleNavClick = (pageName) => {
    setCurrentPage(pageName);
  };

  return (
    <div className="App">
      <Header handleNavClick={handleNavClick} />
      {currentPage === 'home' && <Home />}
      {currentPage === 'home' && <About />}
      {currentPage === 'home' && <Lims />}
      {currentPage === 'home' && <Eln />}
      {currentPage === 'home' && <Adbs />}
      {currentPage === 'home' && <Qms />}
      {currentPage === 'home' && <Dms />}
      {currentPage === 'careers' && <Careers />}
      {currentPage === 'demo' && <Demo />}
      {currentPage === 'home' && <ContactUs />}
      {currentPage === 'downloads' && <Downloads />}
      <Footer handleNavClick={handleNavClick} />
      <div className='d-none'>
        <SmallScreensNavbar handleNavClick={handleNavClick} />

      </div>
      <div className='d-none'>
        <ContactUs handleNavClick={handleNavClick} />
      </div>
      <div >
        <CookiePolicy/>
      </div>


    </div>
  );
}

export default App;
