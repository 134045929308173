import React from 'react';
import 'bootstrap';
import 'aos'
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/adbs-1.png';
import dashboards from '../assets/img/Lims/interactivedashboradlims.png'
import dashboards2 from '../assets/img/Lims/interactivedashboardlims2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons';



const Adbs = () => {
    return (<div classNameName='portifoliobody'>
        <main id="main">
            <section id="ADBS" className="about text-start">
                <div className="section-title text-center" data-aos="zoom-out">
                    <h2>Products</h2>
                    <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)'}}>LAB ICONICS ADBS</h1>
                </div>

                <div className="container shadow-lg rounded-5">
                    <div className='row'>
                        <div className='col-lg-6 mt-2' style={{ fontSize: '17px',fontFamily: 'Arial'}}>
                            <ul>
                                <li>Lab Iconics ADBS (Automated Data Backup System) is a software solution that automates the process of backing up important data. The purpose of Lab Iconics ADBS is to ensure that important data is regularly backed up and protected in case of data loss or corruption due to hardware failures, software errors, or other reasons.</li>
                                <li>Lab Iconics ADBS typically work by copying data from a source, such as a computer or a server, to a secondary location, such as an external hard drive, a network-attached storage (NAS) device, or a cloud-based storage service. The backup process is automated and can be scheduled to occur at specific intervals, such as daily, weekly, or monthly.</li>
                                <li>Lab Iconics ADBS salient features includes data compression, data encryption, and versioning. Disaster recovery features, such as the ability to restore data to a different location or a virtual machine in case of a disaster. This can help ensure that important data is always available, even in the event of a catastrophic failure.</li>
                                <li>Overall, Lab Iconics ADBS is an essential tool for protecting important data and ensuring business continuity. By automating the backup process and providing advanced features such as compression, encryption, and versioning, an Lab Iconics ADBS helps to ensure that critical data is safe and secure.</li>
                            </ul>
                        </div>
                        <div className='col-lg-6 d-flex flex-column justify-content-center'>
                            <img src={lab}  className='adbsimg'/>
                        </div>
                    </div>
                </div>

                {/* ADBS ARCHITECTURE */}
                <div className='container'>
                    <div class="row">
                        <div className="section-title text-center mt-5" data-aos="zoom-out">
                            <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)' }}>ADBS ARCHITECTURE</h1>
                        </div>
                        <div class="col-md-4 ml-lg-5 containe" >
                            <div class="containe__sources">

                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/docu-log.png')} style={{ maxHeight: '60px', maxWidth: '60px', alignItems: 'center' }} /></i>
                                    <h3>Documents </h3>
                                </div>
                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/files-log.png')} style={{ maxHeight: '60px', maxWidth: '60px', alignItems: 'center' }} /></i>
                                    <h3>Files</h3>
                                </div>
                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/lab-appl-log.png')} style={{ maxHeight: '60px', maxWidth: '60px', alignItems: 'center' }} /></i>
                                    <h3>Lab Application Data </h3>
                                </div>
                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/database-log.png')} style={{ maxHeight: '60px', maxWidth: '60px', alignItems: 'center' }} /></i>
                                    <h3>Database Files </h3>
                                </div>
                            </div>


                            <svg viewbox="0 0 10 100">
                                <line x1="145" x2="145" y1="0" y2="100" className="" />
                            </svg>


                            <div class="containe__build">

                                <div class="build">
                                    <i><img src={require('../assets/img/ADBS/center-adbs-log.png')} style={{ maxHeight: '60px', maxWidth: '60px' }} /></i>
                                    <h3>LAB ICONICS ADBS</h3>
                                </div>

                            </div>

                            <svg viewbox="0 0 10 100">
                                <line x1="140" x2="140" y1="0" y2="100" />
                            </svg>


                            <div class="containe__sources">

                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/data-cen-log.png')} style={{ maxHeight: '60px', maxWidth: '60px' }} /></i>
                                    <h3>Data Center</h3>
                                </div>
                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/cloud-st-log.png')} style={{ maxHeight: '60px', maxWidth: '60px' }} /></i>
                                    <h3>Cloud Storage</h3>
                                </div>
                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/stor-med-log.png')} style={{ maxHeight: '60px', maxWidth: '60px', marginTop: '30px' }} /></i>
                                    <h3>Storage Media</h3>
                                </div>
                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/lab-stor-log.png')} style={{ maxHeight: '60px', maxWidth: '60px' }} /></i>
                                    <h3>Local Storage</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 container"></div>
                        <div class="col-md-2 containe">
                            <div class="containe__sources">

                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/encryp-log.png')} style={{ maxHeight: '60px', maxWidth: '60px' }} /></i>
                                    <h3>Encryption</h3>
                                </div>

                            </div>
                            <svg viewbox="0 0 10 100">

                            </svg>
                            <div class="containe__sources">

                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/data-log.png')} style={{ maxHeight: '60px', maxWidth: '60px' }} /></i>
                                    <h3>Data log</h3>
                                </div>

                            </div>


                            <svg viewbox="0 0 10 100">

                            </svg>
                            <div class="containe__sources">

                                <div id="containe__hover" class="sources">
                                    <i><img src={require('../assets/img/ADBS/restoration-log.png')} style={{ maxHeight: '60px', maxWidth: '60px' }} /></i>
                                    <h3>Restoration</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* KEY FEATURES */}
                <div className="services">
                    <div class="container services mt-5" >
                        <div class="row">
                            <div className="section-title text-center" data-aos="zoom-out">
                                <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)'}}>KEY FEATURES</h1>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="fas fa-clock"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a> BACKUP SCHEDULING</a></h4>
                                    <p class="description">The LAB ICONICS ADBS provides the provision to organize the backup on time basis i.e., days, weeks, months. So, system will back up the data automatically without any human intervention.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon d-flex flex-column justify-content-center" style={{fontSize:'60px'}} >
                                        <FontAwesomeIcon icon={faBoxArchive}/>
                                    </div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >ARCHIVAL & RETRIEVAL</a></h4>
                                    <p class="description">The selected data can be stored in Archives, can be restored back. LAB ICONICS ADBS provides smooth transactions for the archives as well as retrievals.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="fas fa-user"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a > LOGGING & REPORTING</a></h4>
                                    <p class="description">The Detailed information of backup data can be provided in the form of reports. LAB ICONICS ADBS provides credentials to login and manage the master data.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><i class="bi bi-device-hdd-fill"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a>DATA / BACKUP CLEAN UP</a></h4>
                                    <p class="description">The duplicate data and the cache data will be cleaned in the regular intervals for the clear and faster execution of the backup.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="bi bi-hdd-rack-fill"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >BACKUP ENCRYPTION</a></h4>
                                    <p class="description">When it's come to the security, LAB ICONICS ADBS will encrypt the backup data while transmission. LAB ICONICS ADBS tend to protect backup from data breach and security threats. So, Data Security is very reliable with encryption of backup data while transmission.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><i class="bi bi-arrows-angle-expand"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >IMPORT & EXPORT BACKUP</a></h4>
                                    <p class="description">The LAB ICONICS ADBS offers the essential features like Import and Export. It helps in sharing the backup data to the local PC and other cloud platforms.The provision of sharing the backup data is very flexible in LAB ICONICS ADBS so that user can export/import the backup data into various drives and virtual cloud.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* HOSTING OPTIONS */}



                <svg className="hero-waves mt-5" style={{ background: 'rgba(4,52,111)' }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none">
                    <defs>
                        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="wave1">
                        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                    <g className="wave2">
                        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                    </g>
                    <g className="wave3">
                        <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
                    </g>
                </svg>
            </section>
        </main>



    </div>

    );
};

export default Adbs;