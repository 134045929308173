import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

const ContactUs = ({ handleNavClick }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");
    const [errormessage, setErrormessage] = useState("");

    const [error, setError] = useState("");
    const captchaRef = useRef(null);
    useEffect(() => {
        console.log("ReCAPTCHA site key:", process.env.REACT_APP_RECAPTCHA_SITE_KEY);
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();
        setError('');
        setErrormessage('');
        if (name && email && companyname && number && message) {
            let token = captchaRef.current.getValue();
            if (token) {
                let valid_token = await verifyToken(token);
                if (valid_token.success) {
                    setErrormessage("Hurray!! you have submitted the form");
                } else {
                    setError("Sorry!! Token invalid");
                }
            } else {
                setError("You must confirm you are not a robot");
            }
        } else {
            setError("First name and Last name are required");
        }
    }

    const verifyToken = async (token) => {
        try {
            let response = await axios.post(`http://localhost:3000/verify-token`, {

                secret: '6LeShfUpAAAAAClX_Jrd51C3FkWJos515YuhVG7R',
                token
            }, console.log(token));
            return response.data;
        } catch (error) {
            console.log("error ", error);
        }
    }

    return (
        <section id="CONTACT US" className="contact">
            <div className="container">
                <div className="section-title" data-aos="zoom-out">
                    <p style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }} className='text-center'>Contact Us</p>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-4" data-aos="fade-right">
                        <div className="info">
                            <div className="address">
                                <a
                                    href="https://www.google.com/maps?q=1007,+10th+Floor,+Manjeera+Trinity+Corporate,+Kukatpally+Housing+Board+Colony,+Kukatpally,+Hyderabad+500072,+Telangana,+India"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'inherit', textDecoration: 'none' }}
                                > <i className="bi bi-geo-alt"></i></a>
                                <h4 className='text-start' style={{ color: 'rgba(4,52,111)' }}>Location:</h4>
                                <p className='text-start'><a
                                    href="https://www.google.com/maps?q=1007,+10th+Floor,+Manjeera+Trinity+Corporate,+Kukatpally+Housing+Board+Colony,+Kukatpally,+Hyderabad+500072,+Telangana,+India"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'black', textDecoration: 'none', fontSize: '15px', fontStyle: 'Arial' }}
                                >1007, 10th Floor, Manjeera Trinity Corporate,
                                    Kukatpally Housing Board Colony,
                                    Kukatpally, Hyderabad– 500072,
                                    Telangana. India.</a></p>
                            </div>
                            <div className="email">
                                <a href="mailto:info@labiconics.com" > <i className="bi bi-envelope"></i></a>
                                <h4 className='text-start' style={{ color: 'rgba(4,52,111)' }}>Email:</h4>
                                <p className='text-start' ><a href="mailto:info@labiconics.com" className='text-dark' style={{ fontSize: '15px', textDecoration: 'none' }}>info@labiconics.com</a></p>
                            </div>
                            <div className="phone">
                                <a href="tel:+918008003103" style={{ color: 'inherit', textDecoration: 'none' }}><i className="bi bi-phone"></i></a>
                                <h4 className='text-start' style={{ color: 'rgba(4,52,111)' }}>Call:</h4>
                                <p className='text-start'>
                                    <a href="tel:+918008003103" style={{ color: 'black', textDecoration: 'none', fontSize: '15px' }}>+91 8008003103</a> /
                                    <a href="tel:+918008009926" style={{ color: 'black', textDecoration: 'none', fontSize: '15px' }}>+91 8008009926</a>
                                </p>
                                <p> </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">

                        <form onSubmit={handleSubmit} encType='text/plain' className="php-email-form">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        onChange={e => setName(e.target.value)}
                                        placeholder="Your Name"
                                        required
                                    />
                                </div>
                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        onChange={e => setEmail(e.target.value)}
                                        placeholder="Your Email"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <input
                                        type="text"
                                        name="companyname"
                                        className="form-control"
                                        onChange={e => setCompanyname(e.target.value)}
                                        placeholder="Company Name"
                                        required
                                    />
                                </div>
                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                    <input
                                        type="tel"
                                        name="number"
                                        className="form-control"
                                        onChange={e => setNumber(e.target.value)}
                                        placeholder="Phone Number"
                                        maxLength="12"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <textarea
                                    name="message"
                                    className="form-control"
                                    onChange={e => setMessage(e.target.value)}
                                    rows="5"
                                    placeholder="Message"
                                    required
                                ></textarea><br></br>
                            </div>

                            <div className="text-center d-flex flex-row justify-content-center">
                                <ReCAPTCHA sitekey='6LfQPPwpAAAAAMG3JAezzQkxr7Zgxodyg7GGgwGj' ref={captchaRef} />
                            </div>
                            <br></br>
                            <div className="text-center">
                                <button type="submit" style={{ background: '#47b2e4', borderRadius: '0px' }}>Send Message</button>
                            </div>
                            <div className='text-center'>
                                {
                                    error && <p className='textError text-center' style={{ color: 'red' }}>Error!! {error}</p>
                                }
                                {
                                    errormessage && <p className='textSuccess text-center' style={{ color: 'red' }}>Success!! {errormessage}</p>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default ContactUs;
