import React from 'react';
import 'bootstrap';
import 'aos'
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/portfolio/Labiconicslogo.png'
import log4 from '../assets/img/logos-eln/log-4.png'
import log5 from '../assets/img/logos-eln/log-5.png'
import log6 from '../assets/img/logos-eln/log-6.png'
import log7 from '../assets/img/logos-eln/log-7.png'
import log8 from '../assets/img/logos-eln/log-8.png'
import log9 from '../assets/img/logos-eln/log-9.png'
import log10 from '../assets/img/logos-eln/log-10.png'
import log11 from '../assets/img/logos-eln/log-11.png'
import log13 from '../assets/img/logos-eln/log-13.png'
import Templog from '../assets/img/logos-eln/Temp-log.png'

const Qms = () => {
    return (<div classNameName='portifoliobody'>
        <main id="main">
            <section id="QMS" className="about text-start">
                <div className="container">

                    <div className="section-title text-center" data-aos="zoom-out">
                        <h2>Products</h2>
                        <h1 className='mt-3' style={{ fontWeight: 'bold',color:'rgba(4,52,111)'}}>QUALITY MANAGEMENT SYSTEM QMS</h1>
                    </div>
                    <div>
                        <p>
                            Lab Iconics Quality Management System streamlines and automates quality control processes, in compliance with industry standards and regulations, and continuous improvement. Lab Iconics QMS encompasses functions such as deviation on and incident management, change management, audit management, risk assessment, and corrective ac on tracking. By centralizing quality related data and processes, it enhances visibility, accountability, efficiency and plays a pivotal role in enhancing product or service quality, customer satisfaction on, and overall organizational excellence.
                        </p>
                    </div>
                    {/* qms architecture */}
                    <div className='qms'>
                        <div className='container d-flex flex-row'>
                            <div className='row'>
                                <div className='col-lg-2 m-4' style={{width:'200px',height:'200px'}}>
                                    <div className='qmsarchitech' style={{height:'23vh'}}><img src={require('../assets/img/teams/Deviationmanagent logo.png')} style={{ width: '150px', height: 'auto' }} /></div>
                                    <p style={{height:'18vh'}}>Deviation Management</p>
                                </div>
                                <div className='col-lg-2 m-4' style={{width:'200px',height:'200px'}}>
                                    <div className='qmsarchitech' style={{height:'23vh'}}><img src={require('../assets/img/teams/incidentdocumentation.png')} style={{ width: '150px', height: 'auto' }} /></div>
                                  <p style={{height:'18vh'}}>Incident Management</p>
                                </div>
                                <div className='col-lg-2 m-4' style={{width:'230px',height:'200px'}}>
                                   <div className='qmsarchitech' style={{height:'23vh'}}> <img src={require('../assets/img/teams/Investigationmanagement logo.png')} style={{ width: '150px', height: 'auto' }} /></div>
                                    <p style={{height:'18vh'}}>Investigation Management</p>
                                </div>
                                <div className='col-lg-2 m-4' style={{width:'200px',height:'200px'}}>
                                    <div className='qmsarchitech' style={{height:'23vh'}}><img src={require('../assets/img/teams/changemanagement.png')} style={{ width: '150px', height: 'auto' }} /></div>
                                    <p style={{height:'18vh'}}>Change Management</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='qms'>
                        <div className='container d-flex flex-row'>
                            <div className='row'>
                                <div className='col-lg-2 m-4' style={{width:'200px',height:'200px'}}>
                                   <div className='qmsarchitech' style={{height:'23vh'}}> <img src={require('../assets/img/teams/risk logo.jpg')} style={{ width: '150px', height: 'auto' }} /></div>
                                    <p style={{height:'18vh'}}>Risk Management</p>
                                </div>
                                <div className='col-lg-2 m-4' style={{width:'200px',height:'200px'}}>
                                   <div className='qmsarchitech' style={{height:'23vh'}}> <img src={require('../assets/img/teams/CAPAlogo.jpg')} style={{ width: '150px', height: 'auto' }} /></div>
                                    <p style={{height:'18vh'}} >CAPA Management</p>
                                </div>
                                <div className='col-lg-2 m-4' style={{width:'200px',height:'200px'}}>
                                    <div className='qmsarchitech' style={{height:'23vh'}}><img src={require('../assets/img/teams/Auditmanagementlogo-Photoroom.png-Photoroom.png')} style={{ width: '150px', height: 'auto' }} /></div>
                                    <p style={{height:'18vh'}}>Audit Management</p>
                                </div>
                                <div className='col-lg-2 m-4' style={{width:'200px',height:'200px'}}>
                                   <div className='qmsarchitech' style={{height:'23vh'}}> <img src={require('../assets/img/teams/Suppliermanagementlogo-Photoroom.png-Photoroom.png')} style={{ width: '200px', height: 'auto' }} /></div>
                                    <p style={{height:'18vh'}}>Supplier Management</p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="services" style={{ marginTop: '100px' }}>
                        <div class="container services mt-5" >
                            <div class="row">
                                <div className="section-title text-center" data-aos="zoom-out">
                                    <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)' }}>KEY FEATURES</h1>
                                </div>

                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                        <div class="icon"><i><img src={require('../assets/img/teams/CAPAlogo.jpg')} style={{width:'50px'}}/></i></div>
                                        <h4 class="title" style={{ color:'rgba(4,52,111)'}}><a >CAPA Management Incident Management</a></h4>
                                        <p class="description">CAPA Management Corrective and Preventive Action (CAPA) management within the Lab Iconics QMS is a systematic approach to identify and rectify deviations and preventing their recurrence. It ensures that when problems arise, they are addressed and that steps are taken to prevent them from happening again. This process improves product quality, regulatory compliance, and overall operational efficiency, while following a culture of continuous improvement.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                        <div class="icon ml-5"><i><img src={require('../assets/img/teams/changemanagement.png')} style={{width:'50px'}}/></i></div>
                                        <h4 class="title" style={{ color:'rgba(4,52,111)'}}><a >Change Management </a></h4>
                                        <p class="description">Change Management in Lab Iconics QMS is a formalized process for requesting modifications, updates, or improvements to the system. It typically involves documenting the proposed change, evaluating its impact on quality, compliance, and operations, and seeking approvals before implementing the change.</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                        <div class="icon"><i><img src={require('../assets/img/teams/risk logo.jpg')} style={{width:'50px'}}/></i></div>
                                        <h4 class="title" style={{ color:'rgba(4,52,111)'}}><a >Risk Management </a></h4>
                                        <p class="description">Lab Iconics QMS Risk Management helps organizations identify, assess, mitigate, and monitor risks related to their quality processes and operations, to proactively address potential risks related to quality, compliance, and operations. It helps organizations make informed decisions, protect their reputation, and ensure the highest levels of product quality and safety.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                        <div class="icon ml-5"><i><img src={require('../assets/img/teams/Auditmanagementlogo-Photoroom.png-Photoroom.png')} style={{width:'50px'}}/></i></div>
                                        <h4 class="title" style={{ color:'rgba(4,52,111)'}}><a>Audit Management </a></h4>
                                        <p class="description">Audit management within the Lab Iconics QMS involves systematic evaluations to assess alignment with quality and effectiveness. This includes evaluation of established controls and ensuring stakeholder awareness of responsibilities. Also, continuous monitoring, tracking, and reporting of findings to ensure timely corrective actions for sustained compliance with quality standards and regulations. </p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                        <div class="icon"><i><img src={require('../assets/img/teams/Deviationmanagent logo.png')} style={{width:'50px'}}/></i></div>
                                        <h4 class="title" style={{ color:'rgba(4,52,111)'}}><a>Deviation Management </a></h4>
                                        <p class="description">Lab Iconics QMS ensures that processes are consistently followed and deviations are properly addressed. This can improve the quality of products and services by identifying and correcting issues before they become larger problems. It also helps to prevent deviations from occurring in the future by identifying root causes and implementing corrective actions. </p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                        <div class="icon ml-5"><i><img src={require('../assets/img/teams/Suppliermanagementlogo-Photoroom.png-Photoroom.png')} style={{width:'80px'}}/></i></div>
                                        <h4 class="title" style={{ color:'rgba(4,52,111)'}}><a >Supplier Management </a></h4>
                                        <p class="description">Supplier Management in Lab Iconics QMS software is a systematic approach to overseeing and controlling relationships with external vendors and partners. It involves processes for evaluating, selecting, monitoring, and collaborating with vendors to ensure they meet quality and compliance standards.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    );
};

export default Qms;