//SmallScreensNavbar.js
import React, { useState } from 'react';
import Navbar from './Navbar'
import { Link } from 'react-scroll';


const SmallScreensNavbar = ({ onClick, navClass, linkClassName, handleNavClick }) => {
    // declare 'translate' as a state variable
    let [translate, setTranslate] = useState(true);
    return (
        <div>
            <button className="hamburger-btn"
                onClick={() => setTranslate(!translate)}>
                {translate ? <span>&#9776;</span> : <span>&times;</span>}
            </button>

            <div id="sidebar-list" className={`${translate ? "addTransiton" : "removeTransition"}`} >
                <div>
                    <Link
                        to='HOME'
                        href='#HOME'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('home')}>
                        <p className='text-start'><b>HOME</b></p>
                    </Link>

                    <Link to='ABOUT'
                        href='#ABOUT'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('home')}>
                        <p className='text-start'><b>ABOUT</b></p>
                    </Link>
                    <Link to='LIMS'
                        href='#LIMS'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('home')}>
                        <p className='text-start'><b>LIMS</b></p>
                    </Link>
                    <Link to='ELN'
                        href='#ELN'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('home')}>
                        <p className='text-start'><b>ELN</b></p>
                    </Link>
                    <Link to='ADBS'
                        href='#ADBS'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('home')}>
                        <p className='text-start'><b>ADBS</b></p>
                    </Link>
                    <Link to='QMS'
                        href='#QMS'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('home')}>
                        <p className='text-start'><b>QMS</b></p>
                    </Link>
                    <Link to='DMS'
                        href='#DMS'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('home')}>
                        <p className='text-start'><b>DMS</b></p>
                    </Link>
                    <Link to='CAREERS'
                        href='#CAREERS'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('careers')}>
                        <p className='text-start'><b>CAREERS</b></p>
                    </Link>
                    <Link to='CONTACT US'
                        href='#CONTACT US'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('home')}>
                        <p className='text-start'><b>CONTACT US</b></p>
                    </Link>
                    <Link to='DOWNLOADS'
                        href='#DOWNLOAD'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('downloads')}>
                        <p className='text-start'><b>DOWNLOADS</b></p>
                    </Link>
                    <Link to='REQUEST A DEMO'
                        href='#REQUEST A DEMO'
                        smooth={true}
                        className={`${linkClassName} linkClassName`}
                        onClick={() => handleNavClick('demo')}>
                        <button className='p-2 bg-transparent border-info text-light'>
                            <b>REQUEST A DEMO</b>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default SmallScreensNavbar;
