import React from 'react';
import 'bootstrap';
import 'aos'
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/portfolio/Labiconicslogo.png'
import rabindranadth from '../assets/img/teams/ravee-removebg-preview.png'
import licaPDF from '../assets/pdf/lica.pdf';
import { FaDownload } from 'react-icons/fa';


const Downloads = () => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = licaPDF;
        link.download = 'Lab Iconics LIMS Brochure.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (<div id="DOWNLOADS" >

        <section id='downloads' className='mb-5 d-none d-lg-block'>
            <div class="section-titlee mt-5 mb-5">
                <h2 className='mb-5' style={{ color:'rgba(4,52,111)'}}><b>LAB ICONICS PRODUCT CATALOG</b></h2>
                <p></p>
            </div>
            <div className=''>
                <ul>
                    <div class="download-link mb-2">
                        <span className='ml-5' style={{ marginRight: '100px' }}>Download Lab Iconics Brochure (pdf)</span>
                        <div onClick={handleDownload} ><FaDownload /></div>
                    </div>
                    {/* <div class="download-link mb-2">
                        <span className='ml-5' style={{ marginRight: '100px' }}>Download Lab Iconics ELN Brochure (pdf)</span>
                        <div onClick={handleDownload} ><FaDownload /></div>
                    </div>
                    <div class="download-link mb-2">
                        <span className='ml-5' style={{ marginRight: '100px' }}>Download Lab Iconics ADBS Brochure (pdf)</span>
                        <div onClick={handleDownload} ><FaDownload /></div>
                    </div> */}
                </ul>
            </div>

        </section>
        <section id='downloads' className='mb-5 d-block d-lg-none'>
        <div class="section-titlee mt-5 mb-5">
                <h2 className='mb-5' style={{ color:'rgba(4,52,111)'}}><b>LAB ICONICS PRODUCT CATALOG</b></h2>
                <p></p>
            </div>
            <div>
                <ul>
                    <div class="">
                        <span>Download Lab Iconics Brochure (pdf)</span>
                        <div onClick={handleDownload} ><FaDownload /></div>
                    </div>
                    {/* <div class="">
                        <span>Download Lab Iconics ELN Brochure (pdf)</span>
                        <div onClick={handleDownload} ><FaDownload /></div>
                    </div>
                    <div class="">
                        <span >Download Lab Iconics ADBS Brochure (pdf)</span>
                        <div onClick={handleDownload} ><FaDownload /></div>
                    </div> */}
                </ul>
            </div>

        </section>

    </div>
    );
};

export default Downloads;