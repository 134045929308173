import React from 'react';
import 'bootstrap';
import 'aos'
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/elnhome.png';
import Templog from '../assets/img/logos-eln/Temp-log.png'
import elnLogo from '../assets/img/logos-eln/logo-2.png'
import test1 from '../assets/img/test1.png'
import log4 from '../assets/img/logos-eln/log-4.png'
import log5 from '../assets/img/logos-eln/log-5.png'
import log6 from '../assets/img/logos-eln/log-6.png'
import log7 from '../assets/img/logos-eln/log-7.png'
import log8 from '../assets/img/logos-eln/log-8.png'
import log9 from '../assets/img/logos-eln/log-9.png'
import log10 from '../assets/img/logos-eln/log-10.png'
import log11 from '../assets/img/logos-eln/log-11.png'
import log13 from '../assets/img/logos-eln/log-13.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';


const Dms = () => {
    return (<div classNameName='portifoliobody'>
        <main id="main">
            <section id="DMS" className="about text-start">
                <div className="container">

                    <div className="section-title text-center" data-aos="zoom-out">
                        <h2>Products</h2>
                        <h1 className='mt-3' style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}>DOCUMENT MANAGEMENT SYSTEM DMS</h1>
                    </div>


                    <div>
                        <p>
                            Lab Iconics Document Management System (DMS) is a cutting‐edge solution that automates the entire document lifecycle, from creation to archiving. Lab Iconics DMS optimizes processes such as review, issuance, retrieval, and offers audit trails and e‐signatures for accountability. With structured version control, it streamlines entire process of documentation, improves productivity, ensures compliance, and enhances data security.
                        </p>
                    </div>

                    <div class="d-none d-md-block">
                        <div class="circle-container mb-5 " style={{ marginTop: '100px', backgroundColor: 'rgba(4,52,111,0.8' }}>
                            <div class="circle" id="center-circle"><img src={require('../assets/img/teams/Auditmanagementlogo-Photoroom.png-Photoroom.png')} style={{ width: '100px', height: 'auto', marginBottom: '50px' }} /></div>
                            <div class="circle" id="circle-1"><img src={require('../assets/img/teams/printmanagement.png')} style={{ width: '80px', height: 'auto', marginBottom: '50px' }} /><p style={{ transform: 'translate(80%, -480%)', fontSize: '13px' }}>Print Management</p></div>
                            <div class="circle" id="circle-2"><img src={require('../assets/img/teams/Documentarchivalicon.jpg')} style={{ width: '80px', height: 'auto', marginBottom: '50px' }} /><p style={{ transform: 'translate(49%, -550%)', fontSize: '13px' }}>Document Archival</p></div>
                            <div class="circle" id="circle-3"><img src={require('../assets/img/teams/Documentissuralogo.jpg')} style={{ width: '70px', height: 'auto', marginBottom: '50px' }} /><p style={{ transform: 'translate(30%, -380%)', fontSize: '13px' }}>Document Isuuance & Retrieval</p></div>
                            <div class="circle" id="circle-4"><img src={log11} style={{ width: '80px', height: 'auto', marginBottom: '10px' }} /><p style={{ transform: 'translate(-0%, -370%)', fontSize: '13px' }}>Collaboration Manager</p></div>
                            <div class="circle" id="circle-5"><img src={Templog} style={{ width: '55px', height: 'auto', marginBottom: '50px' }} /><p style={{ transform: 'translate(-0%, -510%)', fontSize: '13px' }}>Template Management</p></div>
                            <div class="circle" id="circle-6"><img src={require('../assets/img/teams/Versioncontrollogo-Photoroom.png-Photoroom.png')} style={{ width: '80px', height: 'auto', marginBottom: '50px' }} /><p style={{ transform: 'translate(-50%, -510%)', fontSize: '13px' }}>Version Control</p></div>
                            <div class="circle" id="circle-7"><img src={log4} style={{ width: '60px', height: 'auto', marginBottom: '20px' }} /><p style={{ transform: 'translate(-90%, -370%)', fontSize: '13px' }}>workflow Management</p></div>
                        </div>
                    </div>
                    <div className='d-sm-block d-md-none'>
                        <div class="circle-container1 mb-5" style={{ marginTop: '100px', backgroundColor: 'rgba(4,52,111,0.8' }}>
                            <div class="circle1" id="center-circle1"><img src={require('../assets/img/teams/Auditmanagementlogo-Photoroom.png-Photoroom.png')} style={{ width: '47px', height: 'auto', marginBottom: '250px' }} /></div>
                            <div class="circle1" id="circle-11"><img src={require('../assets/img/teams/printmanagement.png')} style={{ width: '25px', height: 'auto', marginBottom: '250px' }} /><p style={{ transform: 'translate(0%, -770%)', fontSize: '10px' }}>Print Management</p></div>
                            <div class="circle1" id="circle-21"><img src={require('../assets/img/teams/Documentarchivalicon.jpg')} style={{ width: '25px', height: 'auto', marginBottom: '250px' }} /><p style={{ transform: 'translate(70%, -1085%)', fontSize: '10px' }}>Document Archival</p></div>
                            <div class="circle1" id="circle-31"><img src={require('../assets/img/teams/Documentissuralogo.jpg')} style={{ width: '25px', height: 'auto', marginBottom: '250px' }} /><p style={{ transform: 'translate(30%, -595%)', fontSize: '10px' }}>Document Isuuance & Retrieval</p></div>
                            <div class="circle1" id="circle-41"><img src={log11} style={{ width: '25px', height: 'auto', marginBottom: '250px' }} /><p style={{ transform: 'translate(-100%, -1060%)', fontSize: '10px' }}>Collaboration Manager</p></div>
                            <div class="circle1" id="circle-51"><img src={Templog} style={{ width: '25px', height: 'auto', marginBottom: '250px' }} /><p style={{ transform: 'translate(-240%, -1120%)', fontSize: '10px' }}>Template Management</p></div>
                            <div class="circle1" id="circle-61"><img src={require('../assets/img/teams/Versioncontrollogo-Photoroom.png-Photoroom.png')} style={{ width: '25px', height: 'auto', marginBottom: '250px' }} /><p style={{ transform: 'translate(-250%, -1090%)', fontSize: '10px' }}>Version Control</p></div>
                            <div class="circle1" id="circle-71"><img src={log4} style={{ width: '25px', height: 'auto', marginBottom: '250px' }} /><p style={{ transform: 'translate(-200%, -530%)', fontSize: '10px' }}>workflow Management</p></div>
                        </div>
                    </div>

                    <div className="services">
                        <div class="container services mt-5" >
                            <div class="row">
                                <div className="section-title text-center" data-aos="zoom-out">
                                    <h1 style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}>KEY FEATURES</h1>
                                </div>

                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                        <div class="icon"><i><img src={Templog} style={{ width: '50px' }} /></i></div>
                                        <h4 class="title" style={{ color: 'rgba(4,52,111)'}}><a>Template Management </a></h4>
                                        <p class="description">Lab Iconics DMS's Template Management Streamlines the creation and consistent use of document templates within the organization. It simplifies the process of generating standardized documents, reduces errors, and ensures consistency.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                        <div class="icon ml-5"><i><img src={require('../assets/img/teams/Versioncontrollogo-Photoroom.png-Photoroom.png')} style={{ width: '50px' }} /></i></div>
                                        <h4 class="title" style={{ color: 'rgba(4,52,111)' }}><a >Version Control </a></h4>
                                        <p class="description">A systematic and structured process of Version Control in Lab Iconics DMS used to manage different drafts, iterations, and versions of documents within the organization. It serves as a crucial tool for tracking, organizing, and controlling the evolution of documents, ultimately leading to the creation and maintenance of the final and authoritative versions.</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                        <div class="icon"><i><img src={log4} style={{ width: '50px' }} /></i></div>
                                        <h4 class="title" style={{ color: 'rgba(4,52,111)' }}><a>Workflow Management</a></h4>
                                        <p class="description"> Workflow management allows you to create and automate document‐related business processes, such as approvals, signoffs, notifications, etc. Workflow management can help in improve the efficiency, accuracy, and consistency of document workflows.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                        <div class="icon ml-5"><i><img src={require('../assets/img/teams/Documentissuralogo.jpg')} style={{ width: '50px' }} /></i></div>
                                        <h4 class="title" style={{ color: 'rgba(4,52,111)' }}><a >Document Issuance & Retrieval </a></h4>
                                        <p class="description">The controlled distribution of documents, such as standard operating procedures(SOPs), protocols, batch records, and regulatory submissions, to authorized personnel or entities.</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                        <div class="icon"><i><img src={require('../assets/img/teams/Documentarchivalicon.jpg')} style={{ width: '50px' }} /></i></div>
                                        <h4 class="title" style={{ color: 'rgba(4,52,111)' }}><a >Document Archival</a></h4>
                                        <p class="description"> Structured and secure process of Archival in Lab Iconics DMS ensures the long‐term retention, compliance, and accessibility of critical documents and records throughout the document lifecycle. It supports essential need to maintain data integrity, regulatory adherence, historical reference, and disaster recovery.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 mt-5">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                        <div class="icon ml-5"><i><img src={require('../assets/img/teams/printmanagement.png')} style={{ width: '50px' }} /></i></div>
                                        <h4 class="title" style={{ color: 'rgba(4,52,111)' }}><a >Print Management</a></h4>
                                        <p class="description"> Print management within the Lab Iconics DMS is the oversight of document printing processes. It regulates who can print documents, tracking print activities, and imposing print limits. This control improves document security by ensuring only authorized users print sensitive materials. It also monitors resource consumption, contributing to cost‐effectiveness. Features like encryption and watermarks add an extra layer of security.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </main>



    </div>

    );
};

export default Dms;