//Navbar.js
import React from 'react';
import { Link } from 'react-scroll';

const Navbar = ({ navClass, linkClassName }) => (
  <NavComponent navClass={navClass}
    linkClassName={linkClassName}
  />
);

export const NavComponent = ({ onClick, navClass, linkClassName }) => (
  <nav className={navClass}>
    {["HOME", "ABOUT", "LIMS", "ELN", "ADBS", "QMS", "DMS", "CAREERS", "CONTACT US", "DOWNLOADS"].map(section =>
      <Link to={section}
        smooth={true}
        className={linkClassName}
        onClick={onClick}>
        <p className='text-start'><b>{section}</b></p>
      </Link>
    )}
  </nav>
)
export default Navbar;