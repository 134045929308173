import React from 'react';
import 'bootstrap';
import 'aos'
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/portfolio/limshome.png';
import dashboards from '../assets/img/Lims/interactivedashboradlims.png'
import dashboards2 from '../assets/img/Lims/interactivedashboardlims2.png'
import setupIcon from '../assets/img/Icons/setup-icon.png'
import affordableCost from '../assets/img/Icons/affordable-cost.png'
import collabration from '../assets/img/Icons/collabration.png'
import configurable from '../assets/img/Icons/configurable.png'
import efficiancy from '../assets/img/Icons/efficiancy.png'
import productivity from '../assets/img/Icons/productivity.png'
import compliance from '../assets/img/Icons/compliance.png'
import accuracy from '../assets/img/Icons/accuracy.png'
import easeOfUse from '../assets/img/Icons/ease-of-use.png'



const Lims = () => {
    return (<div classNameName='portifoliobody'>
        <main id="main">
            <section id="LIMS" className="about text-start">
                <div className="section-title text-center" data-aos="zoom-out">
                    <h2>PRODUCTS</h2>
                    <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)'}}>LAB ICONICS LIMS</h1>
                </div>

                <div className="container">
                    <div className='row'>

                        <div className='col-lg-6 mt-2 d-flex flex-column justify-content-center' style={{ fontSize: '17px',fontFamily: 'Arial'}}>

                            <ul>
                                <li>Lab Iconics LIMS (Laboratory Information Management System) is a digital backbone for the labs to improve quality,  compliance and safety throughout product life cycle.</li>
                                <li>Lab Iconics LIMS is designed to improve lab productivity and efficiency, enhancing effectiveness of biotechnology and pharmaceutical laboratory operations,  maintaining a digital trail of samples, experiments, lab workflow and instruments.</li>
                                <li>Features are configurable to help labs customize their sample inward up to certificate of analysis. Basic module of Lab Iconics LIMS meets your needs for collecting, sharing, analyzing and archiving scientific data. </li>
                                <li>Lab Iconics LIMS is like one more resource in your team, automating workflow and tracking all the important sample information, R&D and QC results generated in the lab each day. It allows to pro-actively manage the entire lab process from samples, instrument maintenance, lab consumables, inventories and people. </li>
                            </ul>
                        </div>
                        <div className='col-lg-6 mt-1'>
                            <img src={lab}  className='lablims' />
                        </div>
                    </div>
                </div>
                {/* HOSTING OPTIONS */}
                <div className="services">
                    <div class="container services mt-5" >
                        <div class="row">
                            <div className="section-title text-center" data-aos="zoom-out">
                                <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)' }}>HOSTING OPTIONS</h1>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="bi bi-server"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a>On Premises</a></h4>
                                    <p class="description">Lab Iconics LIMS can be hosted on premises, on the customer’s infrastructural setup deployed & running from within the confines of organization. Customer have the complete control over the infrastructural setup.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><i class="bi bi-cloud"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Cloud Hosting</a></h4>
                                    <p class="description">Lab Iconics LIMS can be hosted on Cloud with choice of customer’s on cloud service provider with use of virtual hardware, network, storage and composite solutions. The security features enables the data security and access to team any where across the globe. </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left">
                                    <div class="icon"><i class="bi bi-briefcase"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >SaaS Model</a></h4>
                                    <p class="description">Lab Iconics LIMS –SaaS Model is subscription based model helps the customer with Zero initial investment. Customer can subscribe the product which are pre-validated, dedicated hosting in cloud with scalable user and product licenses. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Interactive dashboard */}
                <div className="container mt-5">
                    <div className='row'>
                        <div className="section-title text-center" data-aos="zoom-out">
                            <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)'}}>INTERACTIVE DASHBOARD</h1>
                        </div>
                        <div className='col-lg-6 mt-2 d-flex flex-column justify-content-center' style={{ fontSize: '17px',fontFamily: 'Arial'}}>
                            <ul>
                                <li>LAB ICONICS LIMS will generate the trend analysis and can be configured on the Dashboard.System is having a provision to configure the E-mail and SMS for the transactions wherever required.</li>
                            </ul>
                        </div>
                        <div className='col-lg-6'>
                            <img src={dashboards}  className='limsdashboard'/>
                        </div>
                        <div className='col-lg-6 mt-2 d-flex flex-column justify-content-center order-lg-2' style={{fontSize: '17px',fontFamily: "Arial"}}>
                            <ul>
                                <li>One of the salient features of the LAB ICONICS LIMS is the Dashboard which is configurable for the respective user. It represents the data/flow in clear and graphical manner where user has full authority to customize dashboard with required reports and screens of the module. Approval transactions based on the privilege assigned to the user can be configured on the dashboard along with the tasks and alerts.</li>
                            </ul>
                        </div>
                        <div className='col-lg-6 order-lg-1 d-flex flex-row justify-content-center'>
                            <img src={dashboards2} className='limsdashboards' style={{ height: '60vh' }} />
                        </div>
                    </div>
                </div>
                {/* Modules */}
                <div className="services">
                    <div class="container services mt-5" >
                        <div class="row">
                            <div className="section-title text-center" data-aos="zoom-out">
                                <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)'}}>MODULES</h1>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Basic Module</a></h4>
                                    <p class="description">
                                        <li>Administration</li>
                                        <li>Master Data</li>
                                        <li>Sample Management</li>
                                        <li>Report Management</li>
                                        <li>Data Analytics</li>
                                        <li>LDMS</li>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon"><i class="bi bi-card-checklist"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Inventory Management </a></h4>
                                    <p class="description">
                                        <li>Material Management</li>
                                        <li>Chemical Management</li>
                                        <li>Volumetric Solutions</li>
                                        <li>Solution Management</li>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left">
                                    <div class="icon"><i class="fas fa-vial"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Standards Management</a></h4>
                                    <p class="description">
                                        <li>Reference Standard</li>
                                        <li>Working Standard</li>

                                    </p>
                                </div>

                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="bi bi-cart-plus"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Add-On Modules</a></h4>
                                    <p class="description">
                                        <li>Instrument Management</li>
                                        <li>Column Management</li>
                                        <li>Stability Management</li>
                                        <li>Retain Sample</li>
                                        <li>Label Management</li>
                                        <li>Analyst Qualification</li>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><i class="fas fa-microscope"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Microbiology Management</a></h4>
                                    <p class="description">
                                        <li>Media Management</li>
                                        <li>Culture Management</li>
                                        <li>Environment Monitoring</li>
                                        <li>Solution Management</li>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left">
                                    <div class="icon"><i class="fas fa-users-cog"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}>Integration Modules</h4>
                                    <p class="description">
                                        <li>Instrument Interface</li>
                                        <li>SAP Interface</li>
                                        <li>ERP Interface</li>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* KEY FEATURES */}
                <div className="services">
                    <div class="container services mt-5" >
                        <div class="row">
                            <div className="section-title text-center" data-aos="zoom-out">
                                <h1 style={{ fontWeight: 'bold' ,color:'rgba(4,52,111)'}}>KEY FEATURES</h1>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="fas fa-vial"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Sample Management</a></h4>
                                    <p class="description">LAB ICONICS LIMS provides the best and well-organized Sample Management with structured maintenance of records / transactions throughout the life cycle of a particular sample. Few of the main features are analysis request, assignment, analysis execution, accurate & validated system calculations, system generated Certificate of Analysis, Configurable Investigation Flow, Sample Destruction etc. which allows users a systematic and detailed observation of every single sample.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><i class="bi bi-plus-square-fill"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a>Custom Fields</a></h4>
                                    <p class="description">Lab Iconics provides a privilege of creating the “Custom Fields”. These fields can be created and used in any screen across the application. This will help the customer not to compromise on the field required and they can create any number of fields without any additional charge and need of developer support.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="fas fa-user"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a>Efficient Master Data</a></h4>
                                    <p class="description">Master Data in Lab Iconics LIMS is the core process / module to manage and centralize the flow of the sample management with efficient masters creation like Checklists, Templates, Data Sheet, Forms, Test Registration, Specifications, Reports etc.,</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><i class="bi bi-thermometer-low"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a>Test Registration</a></h4>
                                    <p class="description">Provision of creating the Tests irrespective of Products and these tests can be mapped under required specification by defining the limits during Specification Creation. Combination of Qualitative & Quantitative Sub-Tests can be created under a single test.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i class="bi bi-vector-pen"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a>E-sign Configuration</a></h4>
                                    <p class="description">Lab Iconics LIMS has a provision of defining the configuration of “E-Signature” & “No. of Approvals” for each Menu respectively with facility of maintaining the “History” of all the changes performed.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><i class="bi bi-clipboard-data"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a>Configurable Dashboard & Report</a></h4>
                                    <p class="description">Provision of configuring the “Dashboards” for each individual user. Dashboards contains the Graphical and tabular representations of the data selected by respective user. “Reports” can be configured and can be assigned to required group/ role users.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Why Lab Iconics LIMS */}
                <div className="services">
                    <div class="container services mt-5" >
                        <div class="row">
                            <div className="section-title text-center" data-aos="zoom-out">
                                <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)'}}>WHY LAB ICONICS LIMS</h1>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon fs-5"><img src={setupIcon} style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Setup</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Deployment on-premises, Cloud or SaaS base Implementation. </p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><img src={affordableCost} style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Affordable Cost</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Comprehensive, multifunctional LIMS, economical user licence and multi-site access.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left">
                                    <div class="icon"><img src={easeOfUse} style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Ease of Use</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Needs only a browser and an internet connectivity to access.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><img src={accuracy} style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Accuracy</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Instrument calibration, equipment monitoring, analyst qualification results.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><img src={compliance} style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Compliance</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Accuracy in results, digital trial of each transaction is maintained.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left">
                                    <div class="icon"><img src={productivity} style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Productivity</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Automated workflows and standard tasks enhances productivity.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><img src={efficiancy} style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Efficiency</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Automate lab operations, eliminates paper records, reduces margin of human errors.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon ml-5"><img src={configurable } style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Configurable</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Can be configured based on user’s need without changing the codes.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left">
                                    <div class="icon"><img src={collabration} style={{width:'58px'}}/></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)',fontSize:'20px'}}><a >Collaboration</a></h4>
                                    <p class="describe" style={{ fontSize: '16px',fontFamily: 'Arial' }}>Integrated system making knowledge sharing a hassle-free exercise.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>


        <svg className="hero-waves" style={{ background: 'rgba(4,52,111)' }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none">
            <defs>
                <path id="wave-path" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="wave1">
                <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
            </g>
            <g className="wave2">
                <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
            </g>
            <g className="wave3">
                <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
            </g>
        </svg>
    </div>

    );
};

export default Lims;