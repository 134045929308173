import React from 'react';
import 'bootstrap';
import 'aos'
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/portfolio/Labiconicslogo.png'
import rabindranadth from '../assets/img/teams/ravee-removebg-preview.png'


const Demo = () => {
    return (<div classNameName='portifoliobody mt-5' id='REQUEST A DEMO'>
        <main id="main mt-5">
            <section id="about" className="about text-start mt-5">
                <div className="container mt-5">
                    <div className="section-title text-center" data-aos="zoom-out">
                        <h1>REQUEST A DEMO</h1>
                    </div>
                    <div>
                        <iframe width='100%' height='750px' src='https://raveendranath-labiconics.zohobookings.in/portal-embed#/219698000000028008' frameborder='0' allowfullscreen='' > </iframe>
                    </div>
                </div>
            </section>
        </main>


    </div>

    );
};

export default Demo;