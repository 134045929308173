// import React, { useEffect } from 'react';
import React, { useState } from 'react';
import lab from '../assets/img/portfolio/Labiconicslogo.png';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import SmallScreensNavbar from './smallscreennav';
import { useWindowWidthAndHeight } from './customhooks';
import './index.css'
import { Link } from 'react-scroll';



const Header = ({ handleNavClick }) => {
    const [width, height] = useWindowWidthAndHeight();
    console.log(height)
    const [showDropdown, setShowDropdown] = useState(false);

    return (<div>
        <div className='d-none d-lg-block'>
            <header id="header" className="fixed-top d-flex align-items-center">
                <div className="container d-flex align-items-center justify-content-between">
                    <div className="logo">
                        <h1>
                            <a href="/" onClick={() => handleNavClick('home')}>
                                <img src={lab} alt="Labiconics Logo" />
                            </a>
                        </h1>
                    </div>
                    <Navbar expand="lg" variant="dark">
                        <Container>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-light" />
                            <Navbar.Collapse id="basic-navbar-nav mt-sm-5">
                                <Nav className="me-auto mt-auto">
                                    <Nav.Link href="HOME" className='smallermargin' onClick={() => handleNavClick('home')}><b>HOME</b></Nav.Link>
                                    <Nav.Link href="#ABOUT" onClick={() => handleNavClick('home')}><b>ABOUT</b></Nav.Link>

                                    <div className=''>
                                        <NavDropdown
                                            title="PRODUCTS"
                                            id="basic-nav-dropdown"
                                            style={{ fontWeight: 'bold', marginTop: '10px' }}
                                            className='d-none d-lg-block'
                                            onMouseEnter={() => setShowDropdown(true)}
                                            onMouseLeave={() => setShowDropdown(false)}
                                            show={showDropdown}
                                        >
                                            <div className="dropdown-menu-custom d-flex flex-row" style={{ backgroundColor: 'rgba(4,52,111,0.8)' }}>
                                                <NavDropdown.Item href="#LIMS" onClick={() => handleNavClick('home')} style={{ fontSize: '14px' }}><b>LIMS</b></NavDropdown.Item>
                                                <NavDropdown.Item href="#ELN" onClick={() => handleNavClick('home')} style={{ fontSize: '14px' }}><b>ELN</b></NavDropdown.Item>
                                                <NavDropdown.Item href="#ADBS" onClick={() => handleNavClick('home')} style={{ fontSize: '14px' }}><b>ADBS</b></NavDropdown.Item>
                                                <NavDropdown.Item href="#QMS" onClick={() => handleNavClick('home')} style={{ fontSize: '14px' }}><b>QMS</b></NavDropdown.Item>
                                                <NavDropdown.Item href="#DMS" onClick={() => handleNavClick('home')} style={{ fontSize: '14px' }}><b>DMS</b></NavDropdown.Item>
                                            </div>
                                        </NavDropdown>
                                    </div>
                                    <Nav.Link href='#CAREERS' onClick={() => handleNavClick('careers')} className='scrollTop'><b>CAREERS</b></Nav.Link>
                                    <Nav.Link href="#CONTACT US" onClick={() => handleNavClick('home')}><b>CONTACT US</b></Nav.Link>
                                    <Nav.Link href="#DOWNLOADS" onClick={() => handleNavClick('downloads')}><b>DOWNLOADS</b></Nav.Link>
                                    <Nav.Link href='#REQUEST A DEMO'>
                                        <button className='p-2 bg-transparent border-info text-light' onClick={() => handleNavClick('demo')}>
                                            <b>REQUEST A DEMO</b>
                                        </button>
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </header>
        </div>

        <div className='d-sm-block d-lg-none fixed-top'>
            <div>
                <div className="header-inner" id='header'>
                    <div className="logo">
                        <h1>
                            <a href="/" onClick={() => handleNavClick('home')}>
                                <img src={lab} alt="Labiconics Logo" style={{ width: '50vw' }} />
                            </a>
                        </h1>
                    </div>
                    <SmallScreensNavbar navClass="nav-small"
                        linkClassName="nav-small-link" handleNavClick={handleNavClick}
                    />
                </div>
            </div>
        </div>
    </div>
    );
};

export default Header;


