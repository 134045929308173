import React from 'react';
import 'bootstrap';
import 'aos'
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/elnhome.png';
import Templog from '../assets/img/logos-eln/Temp-log.png'
import elnLogo from '../assets/img/logos-eln/logo-2.png'
import test1 from '../assets/img/test1.png'
import log4 from '../assets/img/logos-eln/log-4.png'
import log5 from '../assets/img/logos-eln/log-5.png'
import log6 from '../assets/img/logos-eln/log-6.png'
import log7 from '../assets/img/logos-eln/log-7.png'
import log8 from '../assets/img/logos-eln/log-8.png'
import log9 from '../assets/img/logos-eln/log-9.png'
import log10 from '../assets/img/logos-eln/log-10.png'
import log11 from '../assets/img/logos-eln/log-11.png'
import log13 from '../assets/img/logos-eln/log-13.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';


const Eln = () => {
    return (<div classNameName='portifoliobody'>
        <main id="main">
            <section id="ELN" className="about text-start">
                <div className="section-title text-center" data-aos="zoom-out">
                    <h2>Products</h2>
                    <h1 style={{ fontWeight: 'bold',color:'rgba(4,52,111)'}}>LAB ICONICS ELN</h1>
                </div>

                

                <div className="container">
                    <div className='row'>

                        <div className='col-lg-6 mt-2 order-lg-2' style={{fontSize: '17px',fontFamily: 'Arial'}}>

                            <ul>
                                <li>Lab Iconics ELN (Electronic Lab Notebook) is a software tool specifically designed for scientists and researchers. It provides a secure and organized way to record, store, and share research data and results.It helps in Storing and managing laboratory data, including protocols, results, images, and documents.One of the key feature includes Collaboration with other scientists in real-time by sharing data and results.</li>
                                <li>Labiconics ELN is designed to be user-friendly and intuitive, making it easy for researchers to adopt and use. It is also flexible and customizable, allowing it to be adapted to meet the specific needs of different research fields and laboratory environments.</li>
                                <li>Labiconics ELN is a valuable tool for researchers who want to improve the accuracy, reproducibility, and security of their laboratory work. Automate laboratory workflows, reducing the risk of errors and improving efficiency. Generate reports and charts to visualize data and results.</li>
                            </ul>
                        </div>
                        <div className='col-lg-6 order-lg-1 d-flex flex-row justify-content-center'>
                            <img src={lab} className='elnimg ' />
                        </div>
                    </div>
                </div>
                {/* KEY FEATURES 45 */}
                <div className="services">
                    <div class="container services mt-5" >
                        <div class="row">
                            <div className="section-title text-center" data-aos="zoom-out">
                                <h1 style={{ fontWeight: 'bold' ,color:'rgba(4,52,111)'}}>KEY FEATURES</h1>
                            </div>
                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300" >
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 ml-5 elnkeyfeatures"><img src={Templog} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Template Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 ml-5 elnkeyfeatures"><img src={elnLogo} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Experiment Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={test1} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Test Execution</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log4} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Workflow Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log5} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Report Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log6} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Inventory Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log7} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Instrument Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log8} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Label Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log9} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Barcode Reader</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log10} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>List Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log11} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Collaboration Management</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6 mt-5 mb-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"></div>
                                    <h4 class="title mt-5 elnkeyfeatures"><img src={log13} style={{ width: '40px', height: '40px' }} /></h4>
                                    <p class="text-center" style={{ fontSize: '20px' }}>Third Party Integration</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* HOSTING OPTIONS */}
                <div className="services mt-5">
                    <div class="container services mt-5" >
                        <div class="row">

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                                    <div class="icon"><i> <FontAwesomeIcon icon={faUsersCog} /></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Project Management</a></h4>
                                    <p class="description">The project management in LAB ICONICS ELN plays a key role in managing the experiments under respective projects by, assigning the projects to the respective groups.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                                    <div class="icon"><i class="bi bi-file-earmark-richtext"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Forms & Templates</a></h4>
                                    <p class="description">The features in the LAB ICONICS ELN includes Configurable forms and templates, which helps in saving an ample of time by updating / entering the required details in standard template or forms. </p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left">
                                    <div class="icon"><i> <FontAwesomeIcon icon={faBoxes} /></i></div>
                                    <h4 class="title"  style={{color:'rgba(4,52,111)'}}><a >Inventory Management</a></h4>
                                    <p class="description">Lab Iconics LIMS will efficiently perform the task of digital inventory management by recording, tracking and optimising laboratory chemicals, reagents, standards, and solvents. The system will track maintaining the reconciliation of Inventory usage.

                                    </p>
                                    <div className='container'>
                                        <div>
                                            <div className='d-flex flex-row'>
                                                <div>
                                                    <ul className='ml-5' style={{ marginLeft: '65px',fontFamily: "Arial" ,fontSize: '16px'}}>
                                                        <li>Chemical Management</li>
                                                        <li>Material Management</li>
                                                        <li>Reference Standards</li>
                                                        <li>Working Standards</li>

                                                    </ul>
                                                </div>
                                                <div>
                                                    <ul style={{fontFamily: "Arial" ,fontSize: '16px'}}>

                                                    
                                                        <li>Column Management</li>
                                                        <li>Instrument Management</li>
                                                        <li>Solution Management</li>
                                                    </ul>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left">
                                    <div class="icon"><i class="fas fa-flask"></i></div>
                                    <h4 class="title" style={{color:'rgba(4,52,111)'}}><a >Experiment Management</a></h4>
                                    <p class="description">The experiment management of LAB ICONICS ELN is the core/vital module where a provision to manage the whole experiment, it incorporates features like importing files, media, documents as an attachment/reference, also Chemical Structure drawing is one of the finest features along with the calculations.
                                    </p>
                                    <ul className='ml-5' style={{ marginLeft: '65px',fontSize: '16px',fontFamily: 'Arial' }}>
                                        <li>Sections</li>
                                        <li>Templates</li></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>


        <svg className="hero-waves" style={{ background: 'rgba(4,52,111)' }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none">
            <defs>
                <path id="wave-path" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="wave1">
                <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
            </g>
            <g className="wave2">
                <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
            </g>
            <g className="wave3">
                <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
            </g>
        </svg>
    </div>

    );
};

export default Eln;