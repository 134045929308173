import React from 'react';
import lab from '../assets/img/portfolio/main.png';
import 'bootstrap';
import 'aos';
import hero from '../assets/img/portfolio/hero-img.png'

const Home = () => {
    const sectionStyle = {
        backgroundImage: `url(${lab})`,
        backgroundSize: 'cover', // Adjust to your preference
        backgroundPosition: 'center', // Adjust to your preference
        width: '100%',
        lineHeight: '1', // Ensure full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden'
    };

    return (
        <div id='HOME'>
            <section id='hero' style={sectionStyle}>
                <div id="heroCarousel" className="container carousel carousel-fade" data-bs-ride="carousel">
                    <div className="row carousel-item active d-flex flex-row justify-content-start" >
                        <div className="carousel-container col-12 col-lg-6">
                            <h1 className="animate__animated animate__fadeInDown text-light text-start" ><b>Elevate Your Lab's Efficiency with Intuitive Compliance Solutions</b>
                            </h1>
                           
                            <a href="https://www.youtube.com/watch?v=Oj1V55eBSKU" target="_blank" className="btn-get-started animate__animated animate__fadeInUp scrollto"><b>Watch Video</b></a>
                        </div>
                        <div className="carousel-container col-12 col-lg-6 animate__animated animate__fadeInDown" data-aos="zoom-in" data-aos-delay="200">
                            <img src={hero} alt="Labiconics Logo" className="img-fluid animated" />
                        </div>
                    </div>
                </div>

                {/* SVG Waves */}
                <svg className="hero-waves" style={{ background: 'rgb(4,52,111)' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none">
                    <defs>
                        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="wave1">
                        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                    <g className="wave2">
                        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                    </g>
                    <g className="wave3">
                        <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
                    </g>
                </svg>
            </section>
        </div>
    );
};

export default Home;
